import { Component, OnInit } from '@angular/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// import { LandingComponent } from '../components/landing/landing.component';
// import { ProfileComponent } from '../components/profile/profile.component';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {

    aboutReadenHost_paragraph1: string ='Readen Host is a concept born out of a desire to bring quality, exciting and versatile house music to the crowds so they could feel what I feel each time I would attend a festival or step on the stage and perform.'
    aboutReadenHost_paragraph2: string = 'I was born in Bucharest, Romania and lived there till the age of 18 when I decided to move to the United Kingdom for univeristy. After throwing a number of parties during uni, I\'d gotten very fond of hosting this type of feel-good events. This resulted in playing several events, including twice attending a massive university event called "The End", alongside my friend as the DJ duo A/B Crew.'
    aboutReadenHost_paragraph3: string = 'Studying the craft for several years made me realize that I could bring it to the next level and to large crouwd, so the journey began.'
    aboutReadenHost_paragraph4: string = 'Check out the socials to find out more about what I\'m up to and find the latest releases from my shows & DJ sets such as House with No Rules.'
    constructor() { }

    ngOnInit() {}

}
