import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss']
})
export class CreditsComponent implements OnInit {
    // simpleSlider = 40;
    // doubleSlider = [20, 60];
    // state_default: boolean = true;
    // focus: any;
    constructor() { }

    ngOnInit() {}

}
