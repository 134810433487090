import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  focus: any;
  focus1: any;

  whoIs = "Who is Readen Host?"
  ReadenHost = "Readen Host"
  aka = "aka Alexandru Preda"
  whoIsDescription = "If you got this far I guess you might want to learn more about me, what I do or what House with No Rules is."

  constructor() { }

  ngOnInit() {}

}
